<template>
  <div class="partyMember">
    <div class="tab">
      <div
        v-for="(item, index) in tabList"
        :key="index"
        :class="['item', { active: oIndex == index }]"
        @click="selectMem(item, index)"
      >
        {{ item.label }}
      </div>
    </div>
    <div class="main">
      <v-list
        :finished="finished"
        @onLoad="onLoad"
        ref="list"
        :finishedText="finishedText"
      >
        <div class="main-l" v-if="oIndex == 0">
          <div class="img" v-for="(item, index) in imgList" :key="index">
            <img :src="item" alt="" />
          </div>

          <!-- <div
            class="item"
            v-for="(item, index) in listData"
            :key="index"
            @click="toDetail(item, index)"
          >
            <div class="item-l"><img :src="item.avatarUrl" alt="" /></div>
            <div class="item-r">
              <div class="name">
                <span class="name-l">{{ item.name }}</span>
                <span class="name-r">{{ item.post }}</span>
              </div>
              <div class="duty">
                <span class="duty-l">工作分工：</span>
                <span class="duty-r">
                  {{ item.dutyResp }}
                </span>
              </div>
              <div class="duty">
                <span class="duty-l">主要负责区域：</span>
                <span class="duty-r">{{ item.respArea }}</span>
              </div>
            </div>
          </div> -->
        </div>
        <div class="main-r" v-else>
          <div
            class="item"
            v-for="(item, index) in listData"
            :key="index"
            @click="toDetail(item, index)"
          >
            <div class="item-l"><img :src="item.avatarUrl" alt="" /></div>
            <div class="item-c">
              <div class="name">{{ item.name }}</div>
              <div class="label">
                {{ item.post }}
              </div>
            </div>
            <!-- <div class="item-r" @click="toAlink(item)">
              <img src="./img/tel.png" alt="" />
            </div> -->
          </div>
        </div>
      </v-list>
    </div>
  </div>
</template>

<script>
import { gloabalCount } from "@/utils/common";
import {
  partyMemberUrl,
  getPartyOrganizationInfoByTenantIdUrl,
  partyMemberDetailUrl,
} from "./api.js";
import { getHashParam } from "@/utils/utils.js";
var moment = require("moment");
export default {
  name: "partyMember",
  data() {
    return {
      oIndex: 0,
      tabList: [{ label: "部门架构" }, { label: "干部" }, { label: "党员" }],
      finishedText: "没有更多了",
      finished: false,
      requestData: {
        curPage: 1,
        pageSize: 10,
      },
      listData: [],
      isCadre: 2,
      isPartyMember: 2,
      imgList: [],
    };
  },
  components: {},
  destroyed() {},
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    roomId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  mounted() {
    gloabalCount("", 46, 1);
    this.getPartyOrganizationInfoByTenantId();
  },
  methods: {
    async getPartyOrganizationInfoByTenantId() {
      let params = {
        tenantId: this.tenantId,
      };
      let res = await this.$axios.get(
        `${getPartyOrganizationInfoByTenantIdUrl}`,
        { params: params }
      );
      if (res.code === 200) {
        this.imgList = res.data ? res.data.split(",") : [];
      }
    },
    toAlink(item) {
      window.location.href = `tel://${item.mobile}`;
    },
    toDetail() {},
    selectMem(item, index) {
      this.oIndex = index;
      this.finished = false;
      this.$refs.list._data.loading = true;
      this.requestData.curPage = 1;
      this.listData = [];
      if (index == 1) {
        this.isCadre = 1;
        this.isPartyMember = 2;
        this.getPartyList();
      } else if (index == 2) {
        this.isCadre = 2;
        this.isPartyMember = 1;
        this.getPartyList();
      } else {
        this.isCadre = 2;
        this.isPartyMember = 2;
        this.getPartyList();
      }
    },
    onLoad() {
      this.getPartyList();
    },
    async getPartyList() {
      let params = {};
      if (this.isCadre == 2 && this.isPartyMember == 2) {
        params = {
          curPage: this.requestData.curPage++,
          pageSize: this.requestData.pageSize,
        };
      }
      if (this.isCadre == 1 && this.isPartyMember == 2) {
        params = {
          curPage: this.requestData.curPage++,
          pageSize: this.requestData.pageSize,
          isCadre: this.isCadre,
        };
      }
      if (this.isCadre == 2 && this.isPartyMember == 1) {
        params = {
          tenantId: this.tenantId,
          curPage: this.requestData.curPage++,
          houseId: this.roomId || this.communityId || undefined,
          pageSize: this.requestData.pageSize,
          isPartyMember: this.isPartyMember,
        };
      }
      let res = await this.$axios.get(`${partyMemberUrl}`, { params: params });
      console.log(res, 333);
      if (res.code === 200) {
        if (res.data.pages <= res.data.current) {
          this.finished = true;
        } else {
          this.$refs.list._data.loading = false;
        }
        this.finishedText = "没有更多了";
        if (res.data.records.length === 0) {
          this.finishedText = "";
          return;
        }
        res.data.records.forEach((item) => {
          this.listData.push(item);
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.partyMember {
  min-height: 100vh;
}
.partyMember {
  padding-top: 120px;
  .tab {
    display: flex;
    justify-content: space-between;
    background: #fff;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    box-sizing: border-box;
    z-index: 999;
    .item {
      flex: 1;
      text-align: center;
      padding: 28px 0;
      font-size: 28px;
      font-weight: 400;
      color: #323334;
      line-height: 40px;
    }
    .active {
      position: relative;
      font-weight: 700;
      &::after {
        content: "";
        position: absolute;
        width: 64px;
        height: 8px;
        background: #007eff;
        border-radius: 4px;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
      }
    }
  }
  .main {
    .main-l {
      .img {
        width: 100%;
        img {
          width: 100%;
          display: block;
        }
      }

      .item {
        padding: 22px 18px;
        box-sizing: border-box;
        display: flex;
        height: 278px;
        background: #ffffff;
        border-radius: 16px;
        margin-bottom: 30px;
        box-shadow: 0px 0px 32px 0px rgba(66, 114, 153, 0.1);
        border-radius: 16px;
        .item-l {
          width: 200px;
          height: 234px;
          margin-right: 24px;
          img {
            width: 100%;
            height: 100%;
            border-radius: 8px;
          }
        }
        .item-r {
          flex: 1;
          overflow: hidden;
          .name {
            margin-bottom: 8px;
            padding-left: 12px;

            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            .name-l {
              font-size: 32px;
              font-weight: 700;
              color: #323334;
              line-height: 44px;
              margin-right: 38px;
            }
            .name-r {
              font-size: 24px;
              font-weight: 400;
              color: #ff853a;
              line-height: 34px;
            }
          }
          .duty {
            font-size: 28px;
            color: #030303;
            line-height: 40px;
            margin-bottom: 10px;
            width: 100%;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
            .duty-l {
              font-size: 32px;
              font-weight: 700;
              color: #323334;
              line-height: 44px;
              position: relative;
              padding-left: 12px;
              &::after {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
                margin: auto;
                z-index: 99;
                width: 6px;
                height: 24px;
                background: #ff853a;
                border-radius: 5px;
              }
            }
            .duty-r {
            }
          }
        }
      }
    }
    .main-r {
      padding: 0 32px;
      .item {
        height: 156px;
        background: #ffffff;
        box-shadow: 0px 0px 32px 0px rgba(66, 114, 153, 0.1);
        border-radius: 16px;
        display: flex;
        align-items: center;
        padding: 20px 26px;
        position: relative;
        margin-bottom: 30px;
        .item-l {
          width: 114px;
          height: 114px;
          border-radius: 16px;
          margin-right: 16px;
          img {
            width: 100%;
            height: 100%;
            border-radius: 16px;
          }
        }
        .item-c {
          flex: 1;
          .name {
            font-size: 32px;
            font-weight: 600;
            color: #323334;
            line-height: 44px;
            margin-bottom: 14px;
          }
          .label {
            font-size: 28px;
            font-weight: 400;
            color: #ff853a;
            line-height: 40px;
          }
        }
        .item-r {
          width: 48px;
          height: 48px;
          position: absolute;
          top: 0;
          bottom: 0;
          right: 32px;
          margin: auto;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
</style>
