let origin = "https://future.zhuneng.cn";
//亮相台
const partyMemberUrl = `/gateway/hc-govern/miniapi/cp/cpList`;
//亮相台详情
const partyMemberDetailUrl = `/gateway/hc-govern/miniapi/cp/cpInfo`;
//部门架构
const getPartyOrganizationInfoByTenantIdUrl = `/gateway/hc-serve/partyOrganizationInfo/getPartyOrganizationInfoByTenantId`;
export {
  partyMemberUrl,
  getPartyOrganizationInfoByTenantIdUrl,
  partyMemberDetailUrl,
};
